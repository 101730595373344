<template>
  <div class="sider-com">
    <div class="mb-4 head">最新发布</div>
    <div class="items my-2">
      <div class="item">
        <div class="title fw-bold fs-6">信息数据对比系统</div>
        <div class="my-2 desc">项目类型：系统开发</div>
        <div class="time">
          <span class="qian">￥10000 - 50000</span>
          <span class="shijian">
            <i class="iconfont icon-clock"></i>
            18小时前
          </span>
        </div>
      </div>
      <div class="item">
        <div class="title fw-bold fs-6">信息数据对比系统</div>
        <div class="my-2 desc">项目类型：系统开发</div>
        <div class="time">
          <span class="qian">￥10000 - 50000</span>
          <span class="shijian">
            <i class="iconfont icon-clock"></i>
            18小时前
          </span>
        </div>
      </div>
      <div class="item">
        <div class="title fw-bold fs-6">信息数据对比系统</div>
        <div class="my-2 desc">项目类型：系统开发</div>
        <div class="time">
          <span class="qian">￥10000 - 50000</span>
          <span class="shijian">
            <i class="iconfont icon-clock"></i>
            18小时前
          </span>
        </div>
      </div>
    </div>
    <div class="bottom my-2">
      <img src="../assets/images/yun.jpg" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Sider",
};
</script>

<style lang="scss">
.sider-com {
  // font-size: 20px;
  .head {
    font-size: 18px;
    padding-left: 0.8rem;
    background: #fff;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
  }

  .items {
    background-color: #fff;
    padding: 0 10px;
    .item {
      border-bottom: 1px dashed #ccc;
      padding: 15px 0;
      cursor: pointer;

      .desc {
        font-size: 14px;
        color: #999;
      }
      .time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .qian {
          color: red;
          font-weight: bold;
        }
        .shijian {
          color: #aaa;
          vertical-align: middle;
          i {
            vertical-align: middle;
          }
        }
      }
    }
    .item:last-child {
      border-bottom: none;
    }
  }

  .bottom {
    img {
      width: 100%;
    }
  }
}
</style>