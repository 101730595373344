<template>
  <div class="publish-home">
    <r-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
          <div class="left">
            <div class="form-wrap">
              <el-form label-position="left" label-width="100px"
                :rules="rules" :model="ruleForm"
              >
                <el-form-item label="项目名称"
                  
                > 
                  <el-input
                    placeholder="请输入您的项目名称，如APP开发"
                   />
                </el-form-item>

                <el-form-item label="项目类型">
                                    <div class="label-list">
                    <a href="javascript:;" class="cat-name">方案及设计</a>
                    <a href="javascript:;" class="cat-name">pcb及pcba</a>
                    <a href="#" class="cat-name">治具</a>
                    <a href="#" class="cat-name">工装</a>
                    <a href="#" class="cat-name">夹具</a>
                    <a href="#" class="cat-name">钢网</a>
                    <a href="#" class="cat-name">模具</a>
                    <a href="#" class="cat-name">注塑</a>
                    <a href="#" class="cat-name">嵌入式开发</a>
                    <a href="#" class="cat-name">软件开发</a>
                    <a href="#" class="cat-name">商品</a>
                    <a href="#" class="cat-name">设备</a>
                    <a href="#" class="cat-name">材料</a>
                    <a href="#" class="cat-name">配件</a>
                    <a href="#" class="cat-name">服务</a>
                    <a href="#" class="cat-name">创新小镇</a>
                  </div>
                </el-form-item>

                <el-form-item label="项目预算">
                  <div>
                    <el-radio v-model="radio" label="1" @change="Hbudget()"
                      >有明确预算</el-radio
                    >
                    <el-radio v-model="radio" label="2" @change="Nbudget()"
                      >无明确预算</el-radio
                    >
                    <el-radio v-model="radio" label="3" @change="Discuss()"
                      >待商议</el-radio
                    >
                  </div>
                  <div class="d-flex mt-2">
                    <el-input
                    style="width: 50%;"
                        placeholder="请输入您的预算金额"
                        :disabled="scope1"
                        v-model="ruleForm.projectBudget"
                      ></el-input>
                      <el-select
                      class="ml-3"
                        v-model="value"
                        :disabled="scope2"
                        placeholder="请选择项目预算范围"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
                </el-form-item>

                <el-form-item label="项目周期">
                  <el-input
                      class="period"
                      placeholder="请输入您的项目周期"
                      v-model="ruleForm.projectPeriod"
                    >
                      <template slot="append">天</template>
                    </el-input>
                </el-form-item>

                <el-form-item label="项目需求">
                  <el-input
                      type="textarea"
                      class="el-textarea"
                      :rows="6"
                      placeholder="请输入详细的需求描述，可以帮助您快速找到合适的开发人员（30字以上）：
一、项目简介
二、功能需求
三、人员要求
四、参考产品
五、验收标准"
                      v-model="ruleForm.projectDemand"
                    ></el-input>
                </el-form-item>

                <el-form-item label="上传附件">
                   <el-upload
                      class="upload-demo"
                      drag
                      action="http://106.54.102.175:8081/graphql"
                      multiple
                      :headers="headers"
                      :before-remove="beforeRemove"
                      :limit="3"
                      :http-request="httpRequest"
                      :on-change="fileBroadcastChange"
                      @click="getFile(index)"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        将文件拖到此处，或
                        <em>点击上传</em>
                      </div>
                      <div class="el-upload__tip" slot="tip">
                        可上传3个10M以内的JPG、PNG、PPTX、GIF、DOCX、XLS、XLSX、PPT、PPTX、PDF、ZIP、RAR
                      </div>
                    </el-upload>
                </el-form-item>

                <el-form-item label="合作倾向">
<el-radio v-model="cooperation" label="1">不限</el-radio>
                    <el-radio v-model="cooperation" label="2">个人</el-radio>
                    <el-radio v-model="cooperation" label="3">团队</el-radio>
                    <el-radio v-model="cooperation" label="4">公司</el-radio>
                  
                </el-form-item>
                <el-form-item label="地域限制">
                  <el-radio v-model="zone" label="1" @change="Hzone"
                      >没有</el-radio
                    >
                    <el-radio v-model="zone" label="2" @change="Nzone"
                      >有</el-radio
                    >
                    <el-cascader
                      :disabled="zoneconfine"
                      size="large"
                      :options="optionsZone"
                      v-model="selectedOptions"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="联系方式">
                  <el-row>
                    <el-col :span="12" class="mb-3">
                      <div class="mb-1" style="line-height: initial">姓名</div>
                      <el-input
                        placeholder="请输入您的姓名"
                        clearable
                        v-model="ruleForm.username"
                      ></el-input>
                    </el-col>
                    <el-col :span="12" class="mb-3  pl-3">
                      <div class="mb-1" style="line-height: initial">邮箱</div>
                      <el-input
                        placeholder="请输入您的邮箱"
                        clearable
                        v-model="ruleForm.email"
                      ></el-input>
                    </el-col>
                    <el-col :span="12" class="mb-3">
                      <div class="mb-1" style="line-height: initial">手机号</div>
                      <el-input
                        placeholder="请输入您的手机号码"
                        clearable
                        v-model="ruleForm.phone"
                      ></el-input>
                    </el-col>
                    <el-col :span="12" class="mb-3 pl-3">
                      <div class="mb-1" style="line-height: initial">验证码</div>
                      <el-input
                        placeholder="请输入验证码"
                        v-model="ruleForm.verticalCode"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-form-item>

              </el-form>
                                <div class="text-center">
                    <label>
                    <el-checkbox></el-checkbox>
                     我同意遵守 《云制造发布规则及协议》
                    </label>
                  </div>
                  <div  class="text-center mt-2">
                    <el-button type="primary"
                      style="width: 150px;"
                    >立即发布</el-button>
                  </div>
            </div>

          </div>
        </el-col>

        <!-- 侧边 -->
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <sider />
        </el-col>
      </el-row>
    </r-row>
  </div>
</template>

<script>
import Sider from "../../components/Sider.vue";
const $ = {};
const gql = {};

export default {
  name: "Publish",
  components: {
    Sider,
  },
  data() {
    return {
      radio: "1",
      cooperation: "1",
      headers: { "Content-Type": "multipart/form-data" },
      fileList: [],
      Base64: [],
      issue: true, //发布
      checked: "", //协议
      zone: "1", //区域限制
      zoneconfine: true, //区域限制显示
      T_Time: "",
      logincode_T: true,
      scope1: false,
      scope2: true,
      scope1value: "",
      selectedOptions: "",
      options: [
        {
          value: "选项1",
          label: "0-5千",
        },
        {
          value: "选项2",
          label: "1万-5万",
        },
        {
          value: "选项3",
          label: "5万-10万",
        },
        {
          value: "选项4",
          label: "10万-50万",
        },
        {
          value: "选项5",
          label: "50万以上",
        },
      ],
      value: "",
      optionsZone: [],
      ruleForm: {
        // 表单内容
        projectName: "", //项目名称
        projectBudget: "", //项目金额
        projectPeriod: "", //项目周期
        projectDemand: "", //项目需求
        phone: "", //手机号码
        verticalCode: "", //验证码
        username: "", //姓名
        email: "", //邮箱
      },
      rules: {
        // 表单规则
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
          { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
        ],
        projectBudget: [
          { required: true, message: "请输入项目金额", trigger: "blur" },
          { pattern: /^[0-9]+.?[0-9]*$/, message: "只能输入数字" },
        ],
        projectPeriod: [
          { required: true, message: "请输入项目周期", trigger: "blur" },
          { pattern: /^[0-9]+.?[0-9]*$/, message: "只能输入数字" },
        ],
        projectDemand: [
          { required: true, message: "请输入项目需求", trigger: "blur" },
          { min: 5, max: 30, message: "最少输入5个字符" },
        ],
        username: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { min: 3, max: 10, message: "长度在 3 到 10 个字符" },
          {
            pattern: /^[\u4E00-\u9FA5A-Za-z]+$/,
            message: "只能输入中文和英文",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
            message: "只能输入11位手机号",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^\w+@[a-z0-9]+\.[a-z]{2,4}$/,
            message: "请输入正确的邮箱",
          },
        ],
        verticalCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { pattern: /^[0-9]{6}$/, message: "验证码必须为6位数字" },
        ],
        /* sex: [{ required: true, message: "请选择性别", trigger: "blur" }] */
      },
    };
  },
  methods: {
    // 有明确预算
    Hbudget() {
      this.value = "";
      this.scope1 = false;
      this.scope2 = true;
    },
    // 没有明确预算
    Nbudget() {
      this.scope1value = "";
      this.scope1 = true;
      this.scope2 = false;
    },
    // 待商议
    Discuss() {
      this.value = "";
      this.scope1value = "";
      this.scope1 = true;
      this.scope2 = true;
    },
    // 有区域限制
    Hzone() {
      this.zoneconfine = true;
    },
    // 没有区域限制
    Nzone() {
      this.regionDataPlus = "";
      this.zoneconfine = false;
    },
    // 验证码倒计时
    TelTime() {
      if (!this.T_Time) {
        this.T_Time = 30; // 初始值为30秒
        let clertime = setInterval(() => {
          // 计时器
          this.T_Time--;
          if (this.T_Time <= 0) {
            // 如果减到0则清楚计时器
            clearInterval(clertime);
          }
        }, 1000);
        // ajax请求
      }
    },
    selectAgreement() {
      this.issue = false;
      $(".selectAgreement input").change(() => {
        if (
          $(".selectAgreement input[type='checkbox']").is(":checked") == true
        ) {
          this.issue = false;
        } else {
          this.issue = true;
        }
      });
    },
    fileBroadcastChange(file) {
      this.fileList = file.name.replace(/.+\./, "");
      /* this.fileList.push(file.name.replace(/.+\./, ""));
      console.log(this.fileList); */
    },
    // 文件上传大小限制
    httpRequest(data) {
      const isLt2M = data.file.size / 1024 / 1024 < 10; //这里做文件大小限制
      //文件过滤，html用了accept，不过不考虑兼容下面可以不要
      var FileExt = data.file.name.replace(/.+\./, "");
      if (
        [
          "jpg",
          "png",
          "gif",
          "jpeg",
          "doc",
          "docx",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "pdf",
          "zip",
          "rar",
        ].indexOf(FileExt.toLowerCase()) === -1
      ) {
        /*  this.$message({
          type: "warning",
          message:
            "请上传后缀名为[jpg,png,gif,jpeg,doc,docx,xls,xlsx,ppt,pptx,pdf,zip,rar]的附件！"
            
        }); */
        alert(
          "请上传后缀名为[jpg,png,gif,jpeg,doc,docx,xls,xlsx,ppt,pptx,pdf,zip,rar]的附件！"
        );
        return false;
      } else if (!isLt2M) {
        /*   this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning"
        }); */
        alert("文件过大");
        return false;
      } else {
        // 转base64
        this.getBase64(data.file).then((resBase64) => {
          this.fileBase64 = resBase64.split(",")[1]; //直接拿到base64信息
          // 打印64编码
          console.log(resBase64.split(",")[1]);
        });
        // this.$message.success("文件上传成功");
        alert("文件上传成功");
        console.log(FileExt);
        // this.fileList.push(data.file.name.replace(/.+\./, ""));
        // console.log(this.fileList);
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file); //开始转
        reader.onload = function () {
          fileResult = reader.result;
        }; //转 失败
        reader.onerror = function (error) {
          reject(error);
        }; //转 结束  咱就 resolve 出去
        reader.onloadend = function () {
          resolve(fileResult);
        };
        console.log(fileResult);
      });
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    DataSubmit() {
      // 打印64编码
      // console.log(this.fileBase64);
      // console.log(FileExt);
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($From: String, $Base64: String) {
              Test(From: $From, Base64: $Base64)
            }
          `,
          // Parameters
          variables: {
            From: this.fileList,
            Base64: this.fileBase64,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /* get() {
      console.log(this.value);
      console.log(this.scope1value);
    } */
  },
};
</script>

<style lang="scss">
.publish-home {
  padding-top: 30px;
  padding-bottom: 20px;
  background: #f0f0f0;
  .left {
    background: #fff;

    .form-wrap {
      padding: 30px;
      .el-form-item__label {
        font-weight: bold;
        font-size: 1rem;
      }

          .label-list {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            .cat-name:hover {
              background-color: #4fafc2;
              color: #fff;
              text-decoration: none;
            }
            a {
              display: inline-block;
              padding: 5px 13.5px;
              background-color: #f8f8f8;
              border-radius: 20px;
              font-size: 12px;
              color: #888;
              text-align: center;
              
              line-height: initial;
              margin-right: 10px;
              margin-bottom: 10px;
            }

          }
    }


  }
}
</style>